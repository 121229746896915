<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="tableData" :fields="fields" responsive="sm" per-page=0 :current-page="currentPage"
                :sort-by.sync="sortBy" :no-provider-paging=false :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template v-slot:cell(img_url)="data">
                  <span v-html="data.value"></span>
                </template>

                
                <template v-slot:cell(zhuangtai)="data">
                  
                  <b-button v-if="data.item.zhuangtai == 1" style="margin-right: 10px;" variant="success" >已读
                  </b-button>
                  <b-button v-else style="margin-right: 10px;" >未读
                  </b-button>
                </template>
                <template v-slot:cell(tool)="data">
              
                  <b-button  v-if="data.item.zhuangtai" variant="success" style="margin-right: 10px;" >标记已读
                  </b-button>

                  <b-button  v-else @click="delRows(data.item.id,data.index)" variant="success" style="margin-right: 10px;" >标记已读
                  </b-button>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <div class="paginationClass">
                      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.currentPage" :page-sizes="[10,20, 30, 40, 50]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                      </el-pagination>
                    </div>
                  </ul>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>


  </Layout>
</template>

<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  export default {
    page: {
      title: "免息贴现",
      meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
      return {
        modalShow: false,
        searchInput: "",
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        tableData: '',
        title: "免息贴现",
        items: [
          {
            text: "票据管理",
            href: "/"
          },
          {
            text: "免息贴现",
            active: true
          }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "age",
        sortDesc: false,
        fields: [
          {
            key: "img_url", label: "票面图片", valign: 'middle', formatter(value, key, item) {
              var result = "";
              result = `<a class = "view" target="_blank"  href="${utils.Img_Url + value}"><img style="width:200px;height:80px;"   src="${utils.Img_Url + value}" /></a>`;
              return result;
            }, events: 'operateEvents'
          },
          { key: "date_time", label: "提交时间",formatter(value){
            return utils.ForMatTime(value)
          } },
          { key: "user", label: "联系方式" },
          { key: "zhuangtai", label: "状态" },
          {
            key: "tool", label: "工具栏"
          },
        ],
        dialogVisible: false,
        EditRowData: "",
        LogoFile: "",
        page: {
          pageSize: 10,//每页显示条数
          currentPage: 1,//当前页码数
          total: 0,
        },
        Editindex: ""
      };
    },
    computed: {
      /**
       * Total no. of records
       */
      rows() {
        return this.tableData.length;
      }
    },
    mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length;
    },
    created() {
      this.CheckParentCompany()

      window.addEventListener("keydown", this.handkeyCode, true); //开启监听键盘按下事件

    },
    methods: {
      // 键盘按下事件
      handkeyCode(e) {
        let key = null;
        if (window.event === undefined) {
          key = e.keyCode;
        } else {
          key = window.event.keyCode;
        }
        if (this.modalShow == true && key === 13) {
          this.onSubmit(); //登录事件
        }
      },
      // 跳转子公司
      SubCompany() {
        this.$router.push({
          name: "Index",
          // params: { teamid: teamid, keyPath: this.keyPath },
        });
      },
      SearchParent() {
        console.log(123)
        _API_.get_parent_company({
          name: this.searchInput
        }).then((res) => {
          this.page.total = 10
          this.tableData = res.data
        })
      },
      onSubmit() {
        let that = this
        let fd = new FormData()// FormData 对象
        fd.append('id', this.EditRowData.id)// 文件对象
        fd.append('name', this.EditRowData.name)// 文件对象
        fd.append('piaoxi', this.EditRowData.piaoxi)// 文件对象
        fd.append('leib', this.EditRowData.leib)// 文件对象
        fd.append('Listed_of', this.EditRowData.Listed_of ? 1 : 0)// 文件对象
        fd.append('Listing_number', this.EditRowData.Listing_number)// 文件对象
        fd.append('enterprise', this.EditRowData.enterprise)// 文件对象
        fd.append('guojpj', this.EditRowData.guojpj)// 文件对象
        fd.append('guonpj', this.EditRowData.guonpj)// 文件对象

        if (this.LogoFile) {

          fd.append('img_url', this.LogoFile)// 文件对象
        }

        _API_.ParentCompanyEdit(fd).then((res) => {

          if (res.code == 200) {
            this.$set(this.tableData, this.Editindex, res.data[0])
            this.$message({
              message: res.msg,
              type: 'success',
              duration: 1000,
              onClose() {
                that.modalShow = false
              }
            });
            document.getElementById('LogoFile').outerHTML = document.getElementById('LogoFile').outerHTML
            this.LogoFile = ""
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
              duration: 1000
            });
            document.getElementById('LogoFile').outerHTML = document.getElementById('LogoFile').outerHTML
            this.LogoFile = ""
          }

        })


      },
      EditLogo() {
        this.$refs.LogoUploads.dispatchEvent(new MouseEvent('click'))
      },
      ChangeLogo() {
        this.LogoFile = this.$refs.LogoUploads.files[0]
      },
      handleClose() {
        this.modalShow = false
      },
      /**
       * Search the table data with search input
       */
      delRows(item) {
        _API_.GetBillDiscount({
          id: item,
        }).then((res) => {
          // this.CheckParentCompany()
          this.tableData = res.data
          this.page.total = res.count
        })
      },
      EditRows(Item, Editindex) {
        this.modalShow = true
        this.Editindex = Editindex
        Item.Listed_of = Item.Listed_of == 1 ? true : false
        console.log(Item.Listed_of)
        this.EditRowData = Item
      },
      ModalTrue() {
        this.modalShow = false
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      CheckParentCompany() {
        _API_.GetBillDiscount({
          page: this.page.currentPage,
          size: this.page.pageSize
        }).then((res) => {
          this.tableData = res.data
          this.page.total = res.count
        })
      },
      // 更改Size
      handleSizeChange(size) {
        this.page.currentPage = 1
        this.page.pageSize = size
        this.CheckParentCompany()
      },
      // 更改Page
      handleCurrentChange(page) {
        this.page.currentPage = page
        this.CheckParentCompany()
      }

    }
  };
</script>
<style>
  .el-form-item {
    margin-bottom: 10px
  }
</style>